import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[orgosColumn]'
})
export class ColumnDirective {
  @Input('orgosColumn')
  set size(size: string) {
    if (!size) {
      this.renderer.setStyle(this.el.nativeElement, 'flex-grow', '1');
    } else if (size === '0') {
      this.renderer.setStyle(this.el.nativeElement, 'flex-grow', '0');
      this.renderer.setStyle(this.el.nativeElement, 'flex-basis', '');
    } else {
      this.renderer.setStyle(this.el.nativeElement, 'flex-grow', size);
    }
  }

  constructor(private renderer: Renderer2, private el: ElementRef) {
    renderer.setStyle(el.nativeElement, 'flex-grow', '1');
    renderer.setStyle(el.nativeElement, 'flex-shrink', '0');
    renderer.setStyle(el.nativeElement, 'flex-basis', '0');
    renderer.setStyle(el.nativeElement, 'word-break', 'normal');
  }
}
