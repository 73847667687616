import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GuardLoadingService {
  guardLoading$ = new BehaviorSubject<boolean>(true);

  startGuardLoading() {
    this.guardLoading$.next(true);
  }

  endGuardLoading() {
    this.guardLoading$.next(false);
  }
}
