<div class="sb-container">
  <div class="sb-upper-container">
    <div *ngIf="(currentProgress$ | async) >= 5" class="sb-welcome-container">
      <img [src]="(kioskSession$ | async)?.imageUrl || '/assets/images/horizontal-logo.svg'"/>
      <div *ngIf="(kioskSession$ | async)?.welcomeMessage" class="kenjo-font-size-30px">
        {{ (kioskSession$ | async)?.welcomeMessage }}
      </div>
    </div>
  </div>

  <svg id="rune" width="900" height="900" viewBox="0 0 900 900" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="1" fill-rule="evenodd" clip-rule="evenodd" d="M860.356 426.185L857.408 428.718L889.259 458.9L876.596 472.146L857.993 454.627L847.24 465.05L803.762 420.42L796.538 426.234L737.653 352.666L736.324 353.413L468.479 54.578L103.861 205.508L146.849 323.879L462.098 161.37L483.195 201.975L458.824 213.064L463.309 221.036L468.623 218.046L466.838 216.427L482.614 204.051L484.857 208.037L498.724 198.486L542.619 265.62L548.224 261.592L720.894 484.538L704.827 497.951L729.163 534.985L712.639 546.031L725.638 567.579L314.572 836.476L289.488 798.114L315.022 782.872L310.246 775.939L276.451 796.703L262.414 774.865L258.428 777.108L242.897 752.612L234.925 757.097L220.637 716.16L218.27 716.617L125.201 537.216L142.182 528.536L149.824 545.227L155.138 542.236L145.42 524.965L151.025 520.937L144.006 510.018L327.556 390.125L372.933 347.976L381.737 360.514L401.209 346.934L414.788 366.406L409.184 370.434L411.426 374.42L417.322 369.354L423.135 376.578L437.417 361.545L551.156 475.092L532.017 495.481L514.413 498.389L520.808 503.537L511.383 513.212L516.907 521.474L369.271 629.031L351.539 608.397L353.364 589.879L345.393 594.364L325.709 568.706L434.773 474.981L401.013 447.623L220.411 564.982L304.344 725.035L426.074 646.048L430.85 652.981L646.781 497.377L639.015 485.129L630.753 490.652L465.059 270.778L348.558 331.08L344.073 323.108L104.514 463.145L87.0306 433.626L81.4258 437.654L60.3283 397.049L66.9712 393.312L46.8269 337.302L55.5459 334.146L11.523 243.469L5.17062 246.169L-12.2316 204.36L17.9111 192.647L14.464 184.966L-24.6883 200.873L-42.0905 159.064L162.971 74.2953L166.128 83.0143L461.075 -54.9532L475.155 -25.2674L482.545 -27.6765L486.821 -30.9572L863.549 386.781L854.415 395.418L899.969 440.629L886.559 452.547L860.356 426.185Z" fill="white"/>
  </svg>

  <div class="sb-bottom-container">
    <div class="sb-bottom-content">
      <kenjo-datetime></kenjo-datetime>
    </div>
  </div>
</div>
