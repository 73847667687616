import { Pipe, PipeTransform } from '@angular/core';
import { TranslateWithDataPipe } from './translate-with-data.pipe';

@Pipe({ name: 'duration' })
export class DurationPipe implements PipeTransform {
  constructor(private translateWithDataPipe: TranslateWithDataPipe) {}
  transform(value: number = 0): string {
    if (isNaN(value)) {
      return this.translateWithDataPipe.transform('hoursAndMinutes', { hours: 0, minutes: 0 });
    }
    if (value < 0) {
      value = this.calculateOvernightEntryDuration(value);
    }
    const hours = Math.floor(value / 60);
    const minutes = Math.floor(value % 60);
    return this.translateWithDataPipe.transform('hoursAndMinutes', { hours, minutes });
  }

  calculateOvernightEntryDuration(value: number) {
    return (24 * 60) + value;
  }
}
