import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'kenjo-icon',
  templateUrl: 'icon.component.html',
  styleUrls: ['icon.component.scss'],
})
export class IconComponent implements OnInit {
  @Input() size: number = 24;

  @Input() iconClass: string = '';

  @Input() id: string = '';

  @HostBinding('style.height.px') iconHeight: number = 24;
  @HostBinding('style.width.px') iconWidth: number = 24;
  @HostBinding('style.font-size.px') iconFontSize: number = 24;
  @HostBinding('class.disabled-icon') disabledClass: boolean = false;

  ngOnInit() {
    this.iconHeight = this.size;
    this.iconWidth = this.size;
    this.iconFontSize = this.size;
  }
}
