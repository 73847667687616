import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'kenjo-dialog-container',
  templateUrl: './dialog-container.component.html',
  styleUrls: ['./dialog-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogContainerComponent {
  @Input() title?: string;
  @Input() disabledCloseButton: boolean = false;
  @Input() customDivStyle: { [key: string]: string } = {};
  @Output() clickCloseButton: EventEmitter<void> = new EventEmitter<void>();
}
