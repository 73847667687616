import { Component, OnInit, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'kenjo-raised-button',
  templateUrl: './raised-button.component.html',
  styleUrls: ['./raised-button.component.scss']
})
export class RaisedButtonComponent {
  private _disabled: boolean = false;

  @Input()
  set disabled(disabled: boolean) {
    this._disabled = disabled;
    this.disabledClass = disabled;
  }
  get disabled(): boolean {
    return this._disabled;
  }

  @Input() iconButton: boolean = false;
  @Input() color: 'Basic' | 'Primary' | 'Success' | 'Danger' | 'Disabled' | 'Neutral' | 'Sign-In' | 'Secondary' | 'Upgrade' = 'Primary';
  @Input() invertColors: boolean = false;
  @Input() padding: string = '6.75px 15px';
  @Input() id: string = '';

  @HostBinding('class.brc-disabled') disabledClass = false;
}
