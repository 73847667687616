export const environment = {
  "production": false,
  "PEOPLE_CLOUD_APP_URL": "https://dev-api.kenjo.io",
  "ORGOS_WEB_APP_URL": "https://dev-env.kenjo.io",
  "delay": "false",
  "AMPLITUDE_IGNORE_DOMAINS": " ",
  "AMPLITUDE_API_KEY": "386da99b232512c0fc98ea00489374da",
  "SENTRY_DSN": "https://dd740f467ec778222e0eebce46f3c873@sentry.io/4507492581244928",
  "SLUG_COMMIT_AWS": "<<SLUG_COMMIT>>",
  "APP_NAME_AWS": "kenjo-kiosk-<<ENVIRONMENT>>",
  "ENABLE_ERRORS_BY_CONSOLE": "false"
};