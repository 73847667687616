import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from '../services/translation.service';

@Pipe({
  name: 'translateWithData'
})
export class TranslateWithDataPipe implements PipeTransform {
  constructor(private translationService: TranslationService) {}
  transform(translationKey: string, data: any = {}): string {
    const translation = this.translationService.currentTranslations$.value[translationKey];
    if (translation === undefined) {
      return '';
    }

    if (Object.keys(data).length === 0) {
      return translation;
    }

    const finalValue = translation.split('%%').map((iTerm) => {
      if (iTerm.startsWith('data.') === false) {
        return iTerm;
      }

      let substitutionValue: any = Object.assign({}, data);
      iTerm.split('.').slice(1).forEach((iKey: string) => {
        substitutionValue = substitutionValue[iKey];
      });

      return substitutionValue;
    })
    .join('');

    return finalValue;
  }
}
