<kenjo-dialog-container
  [title]="'todayRequests' | translate"
  (clickCloseButton)="confirmDialog()"
  [customDivStyle]="{ height: '10px' }"
>
  <div class="td-list">
    <ng-container *ngFor="let iTimeOffData of timeOffData">
      <div class="kenjo-row kenjo-align-center td-list-item">
        <div
          class="td-color-bubble kenjo-mr-10px"
          [style]="'background-color: ' + iTimeOffData.color"
        ></div>
        <p>
          {{
            iTimeOffData.name +
              ": " +
              (iTimeOffData.type === "Hour"
                ? iTimeOffData.partOfDay.from +
                  " - " +
                  iTimeOffData.partOfDay.to
                : iTimeOffData.partOfDay)
          }}
        </p>
      </div>
    </ng-container>
  </div>

  <div class="kenjo-mt-40px cd-buttons-row">
    <kenjo-raised-button
      (click)="confirmDialog()"
      [color]="'Success'"
      class="cd-close cd-flex-shrink"
      >{{ "okGotIt" | translate }}</kenjo-raised-button
    >
  </div>
</kenjo-dialog-container>
