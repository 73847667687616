import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EntryMethod } from '@app/check-in/model/kiosk-session.model';
import { ProgressService } from './progress.service';

@Injectable({ providedIn: 'root' })
export class NavigationService {
  constructor(private router: Router, private progressService: ProgressService) { }

  navigate(route: KioskRoute) {
    this.progressService.updateProgress(route);
    this.router.navigate([route]);
  }

  goBack() {
    const prevRoute = this.progressService.getPreviousRoute();
    this.router.navigateByUrl(prevRoute);
  }

  navigateToLastVisitedRoute() {
    const lastVisitedRoute = this.progressService.getLastVisitedRoute();
    this.router.navigateByUrl(lastVisitedRoute);
  }

  goToLogIn() {
    this.router.navigateByUrl('/sign-in/email');
  }

  goToLocations() {
    this.router.navigateByUrl('/locations');
  }

  goToMessage() {
    this.router.navigateByUrl('/setup/message');
  }

  goToTheme() {
    this.router.navigateByUrl('/setup/theme');
  }

  goToMethods() {
    this.router.navigateByUrl('/setup/methods');
  }

  gotToActivated() {
    this.router.navigateByUrl('/setup/activated');
  }

  goToCheckIn(activeMethods: Array<EntryMethod>) {
    if (activeMethods.includes('qrCode')) {
      this.goToQRCode();
      return;
    }

    if (activeMethods.includes('pinCode')) {
      this.goToPinCode();
    }
  }

  goToPinCode() {
    this.router.navigateByUrl('/check-in/pincode');
  }

  goToQRCode() {
    this.router.navigateByUrl('/check-in/qr');
  }

  goToLoggedOut() {
    this.router.navigateByUrl('/sign-in/logged-out');
  }
}

const routes = [
  '/sign-in/email', '/sign-in/code',
  '/locations',
  '/setup/message', '/setup/theme', '/setup/methods',
  '/check-in/pincode', '/check-in/qr', '/check-in/user-attendance'
] as const;
export type KioskRoute = typeof routes[number];
