import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PersistenceService } from './persistence.service';

@Injectable({ providedIn: 'root' })
export class ThemeColorService {
  public selectedThemeColor$ = new BehaviorSubject<string>('kenjo-default');
  public availableColors = [ 'kenjo-default', 'USER_COLOR_1', 'USER_COLOR_2', 'USER_COLOR_3', 'USER_COLOR_4', 'USER_COLOR_5', 'USER_COLOR_6', 'USER_COLOR_7', 'USER_COLOR_8', 'USER_COLOR_9', 'USER_COLOR_10', 'USER_COLOR_11', 'USER_COLOR_12' ]

  private colorMap = {
    'kenjo-default': { background: '#dedede', font: '#333', buttonBackground: '#59e37c', buttonTextColor: '#FFF' },
    'USER_COLOR_1': { background: '#ffae80', font: '#333', buttonBackground: '#ffae80', buttonTextColor: '#333' },
    'USER_COLOR_2': { background: '#8480ff', font: '#FFF', buttonBackground: '#8480ff', buttonTextColor: '#FFF' },
    'USER_COLOR_3': { background: '#80e0ff', font: '#333', buttonBackground: '#80e0ff', buttonTextColor: '#333' },
    'USER_COLOR_4': { background: '#6ddaae', font: '#FFF', buttonBackground: '#6ddaae', buttonTextColor: '#FFF' },
    'USER_COLOR_5': { background: '#fc7e9e', font: '#FFF', buttonBackground: '#fc7e9e', buttonTextColor: '#FFF' },
    'USER_COLOR_6': { background: '#f37a7a', font: '#FFF', buttonBackground: '#f37a7a', buttonTextColor: '#FFF' },
    'USER_COLOR_7': { background: '#ffca73', font: '#333', buttonBackground: '#ffca73', buttonTextColor: '#333' },
    'USER_COLOR_8': { background: '#77eddf', font: '#333', buttonBackground: '#77eddf', buttonTextColor: '#333' },
    'USER_COLOR_9': { background: '#7dc7fa', font: '#FFF', buttonBackground: '#7dc7fa', buttonTextColor: '#FFF' },
    'USER_COLOR_10': { background: '#a6e975', font: '#333', buttonBackground: '#a6e975', buttonTextColor: '#333' },
    'USER_COLOR_11': { background: '#e178f0', font: '#FFF', buttonBackground: '#e178f0', buttonTextColor: '#FFF' },
    'USER_COLOR_12': { background: '#f77ca9', font: '#FFF', buttonBackground: '#f77ca9', buttonTextColor: '#FFF' }
  }
  constructor(private persistence: PersistenceService) { }

  applyStoredColor() {
    const storedThemeColor = this.persistence.getItem('selectedThemeColor');
    if (storedThemeColor) {
      this.setSelectedThemeColor(storedThemeColor);
      this.applyTheme()
    }
  }

  setSelectedThemeColor(value: string) {
    this.selectedThemeColor$.next(value);
    this.persistence.setItem('selectedThemeColor', value);
  }

  applyTheme() {
    const color = this.selectedThemeColor$.getValue();
    document.documentElement.style.setProperty('--kenjo-theme-color', this.colorMap[color].background);
    document.documentElement.style.setProperty('--kenjo-theme-font-color', this.colorMap[color].font);
    document.documentElement.style.setProperty('--kenjo-theme-button-background', this.colorMap[color].buttonBackground);
    document.documentElement.style.setProperty('--kenjo-theme-button-text-color', this.colorMap[color].buttonTextColor);
  }

  restoreTheme() {
    document.documentElement.style.setProperty('--kenjo-theme-color', this.colorMap['kenjo-default'].background);
    document.documentElement.style.setProperty('--kenjo-theme-font-color', this.colorMap['kenjo-default'].font);
    document.documentElement.style.setProperty('--kenjo-theme-button-background', this.colorMap['kenjo-default'].buttonBackground);
    document.documentElement.style.setProperty('--kenjo-theme-button-text-color', this.colorMap['kenjo-default'].buttonTextColor);
  }

  clearSelectedThemeColor() {
    this.selectedThemeColor$.next('kenjo-default');
    this.persistence.clearItem('selectedThemeColor');
    this.restoreTheme();
  }

  computeColor(color: string): typeof this.colorMap[keyof typeof this.colorMap] {
    return this.colorMap[color] || this.colorMap['kenjo-default'];
  }
}
