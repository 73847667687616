import { Pipe, PipeTransform } from '@angular/core';
import { TranslateWithDataPipe } from './translate-with-data.pipe';

@Pipe({
  name: 'compactDuration'
})
export class CompactDurationPipe implements PipeTransform {
  constructor(private translateWithDataPipe: TranslateWithDataPipe) {}

  transform(value: number, showPlus?: boolean): string {
    if (isNaN(value)) {
      return this.translateWithDataPipe.transform('hoursUnit', { hours: 0 });
    }
    const { hours, minutes } = this.getHoursAndMinutes(value);


    if (minutes === 0) {
      return this.translateHours(hours, showPlus)
    }
    if (hours === 0) {
      return this.translateMinutes(minutes, showPlus);
    }

    return this.translateHoursAndMinutes(hours, minutes);
  }

  getHoursAndMinutes(value: number) {
    return {
      hours: Math.floor(value / 60),
      minutes: Math.floor(value % 60)
    }
  }

  translateHours(value: number, showPlus?: boolean) {
    const hours = this.addPlus(value, showPlus);
    return this.translateWithDataPipe.transform('hoursUnit', { hours });
  }

  translateMinutes(value: number, showPlus?: boolean) {
    const minutes = this.addPlus(value, showPlus);
    return this.translateWithDataPipe.transform('minutesUnit', { minutes });
  }

  translateHoursAndMinutes(hours: number, minutes: number) {
    return `${this.translateHours(hours)} ${this.translateMinutes(minutes)}`;
  }

  addPlus(value: number, showPlus?: boolean) {
    return showPlus ? `${value}+` : value;
  }
}
