import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env';

@Injectable({
  providedIn: 'root'
})
export class HttpClientService {
  delay = environment.delay === 'true';

  constructor(private httpClient: HttpClient) {}

  getWithoutAuth = async <T>(url: string): Promise<T> => {
    if (this.delay) {
      await new Promise(res => setTimeout(res, 500));
    }
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.get<T>(url, { headers }).toPromise();
  }

  getWithCredentials = async <T>(url: string): Promise<T> => {
    if (this.delay) {
      await new Promise(res => setTimeout(res, 500));
    }
    const withCredentials = true;
    return this.httpClient.get<T>(url, { withCredentials }).toPromise();
  }

  postWithCredentials = async <T>(url: string, body: any): Promise<T> => {
    if (this.delay) {
      await new Promise(res => setTimeout(res, 500));
    }
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const withCredentials = true;
    return this.httpClient.post<T>(url, body, { headers, withCredentials }).toPromise();
  }

  postWithoutAuth = async <T>(url: string, body: any): Promise<T> => {
    if (this.delay) {
      await new Promise(res => setTimeout(res, 500));
    }
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post<T>(url, body, { headers }).toPromise();
  }

  get = async <T>(url: string, authToken: string): Promise<T> => {
    if (this.delay) {
      await new Promise(res => setTimeout(res, 500));
    }
    const headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', authToken);
    return this.httpClient.get<T>(url, { headers }).toPromise();
  }

  post = async <T>(url: string, body: any, authToken: string): Promise<T> => {
    if (this.delay) {
      await new Promise(res => setTimeout(res, 500));
    }
    const headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', authToken);
    return this.httpClient.post<T>(url, body, { headers }).toPromise();
  }

  put = async <T>(url: string, body: any, authToken: string): Promise<T> => {
    if (this.delay) {
      await new Promise(res => setTimeout(res, 500));
    }
    const headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', authToken);
    return this.httpClient.put<T>(url, body, { headers }).toPromise();
  }
}
