<div class="kenjo-mv-30px kenjo-mh-40px" [ngStyle]="customDivStyle">
  <div *ngIf="title?.length" class="dc-title kenjo-font-size-20px">
    {{ title }}
  </div>
  <kenjo-icon
    [size]="24"
    class="dc-close-button kenjo-cursor-pointer kenjo-ml-40px kenjo-font-color-light-text-757575"
    (click)="clickCloseButton.emit()"
  >
    clear
  </kenjo-icon>
</div>
<div class="dc-content kenjo-pb-30px kenjo-ph-40px">
  <ng-content></ng-content>
</div>
