import {
  IAttendanceBreak,
  IUserAttendance,
} from '@app/check-in/model/user-attendance.model';
import { IAttendancePolicy } from '@app/setup/model/attendance-settings.model';
import * as check from 'check-types';

const MAX_SHIFT_LENGTH = 1440;

export const isAutoDeductBreakNeeded = (
  userAttendance: IUserAttendance,
  attendancePolicy: IAttendancePolicy,
  profileKey: string
) => {
  const reminders = !['admin', 'hr-admin'].includes(profileKey)
    ? attendancePolicy.breakReminder.reminders
    : [];
  const shiftsAndBreaks = calculateShiftAndBreakDuration(userAttendance);
  if (
    check.nonEmptyArray(reminders) &&
    attendancePolicy.breakReminder.autoDeductBreaks
  ) {
    for (const iReminder of reminders) {
      if (
        iReminder.triggeredAfter <=
          shiftsAndBreaks.shiftDuration - shiftsAndBreaks.breakDuration &&
        iReminder.suggestedBreak > shiftsAndBreaks.breakDuration
      ) {
        return true;
      }
    }
  }
  return false;
};

export const calculateShiftAndBreakDuration = (
  userAttendance: IUserAttendance
) => {
  const totalBreakTime = calculateTotalBreakDuration(userAttendance);
  const shiftDurationWithoutBreaks =
    userAttendance?.endTime < userAttendance?.startTime
      ? userAttendance?.endTime - userAttendance?.startTime + MAX_SHIFT_LENGTH
      : userAttendance?.endTime - userAttendance?.startTime;
  return {
    shiftDuration: shiftDurationWithoutBreaks,
    breakDuration: totalBreakTime,
  };
};

export const calculateTotalBreakDuration = (
  userAttendance: IUserAttendance
) => {
  if (checkNotAssignedBreak(userAttendance)) {
    return 0;
  }
  return userAttendance.breaks?.reduce((total, iBreak) => {
    if (
      check.not.assigned(iBreak?.duration) &&
      check.assigned(iBreak?.end) &&
      check.assigned(iBreak.start)
    ) {
      iBreak.duration = iBreak.end - iBreak.start;
    }
    return total + (iBreak?.duration ?? 0);
  }, 0);
};

export const checkNotAssignedBreak = (
  userAttendance: IUserAttendance
): boolean => {
  return (
    check.not.assigned(userAttendance?.breaks) ||
    userAttendance?.breaks?.length === 0
  );
};

export const filterForbiddenUserAttendanceFields = (
  iUserAttendance: IUserAttendance
) => {
  const userAttendance = { ...iUserAttendance };
  const fieldsUsed = Object.keys(userAttendance) ?? [];
  fieldsUsed.forEach((iField) => {
    if (iField.startsWith('s_') || iField?.startsWith('_')) {
      delete userAttendance[iField];
    }
  });
  return userAttendance;
};

export const calculateAutoDeductedBreaksDuration = (userAttendance: IUserAttendance) => {
  const clonedUserAttendance = { ...userAttendance };
  if (!userAttendance?.breaks) return 0;
  clonedUserAttendance.breaks = clonedUserAttendance?.breaks.filter((iBreak) => iBreak.autoDeducted);
  return calculateTotalBreakDuration(clonedUserAttendance);
};