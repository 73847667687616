import { Component, ElementRef, HostBinding, Input, Renderer2 } from '@angular/core';

import { ColumnDirective } from './column.directive';

@Component({
  selector: 'orgos-column',
  template: ` <ng-content></ng-content> `
})
export class ColumnComponent {
  @Input()
  set size(size: string) {
    this.orgosColumn.size = size;
  }

  @HostBinding('attr.orgosColumn') orgosColumn: ColumnDirective = new ColumnDirective(this.renderer, this.el);

  constructor(private renderer: Renderer2, private el: ElementRef) {}
}
