import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'kenjo-datetime',
  templateUrl: './datetime.component.html',
  styleUrls: ['./datetime.component.scss']
})
export class DatetimeComponent implements OnInit, OnDestroy {
  date: Date;
  dateInterval: any;

  ngOnInit(): void {
    this.date = new Date();
    this.dateInterval = setInterval(() => {
      this.date = new Date();
    }, 1000);
  }

  ngOnDestroy(): void {
    clearInterval(this.dateInterval);
  }
}
