import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'kenjo-secondary-button',
  templateUrl: './secondary-button.component.html',
  styleUrls: ['./secondary-button.component.scss']
})
export class SecondaryButtonComponent implements OnInit {
  @Input() title: string;
  @Input() buttonId?: string = 'submit-button';
  @Input() flexWidth: boolean = false;
  @Input() size: 'huge' | 'large' | 'medium' = 'large';

  @Input() loading: boolean = false;
  @Input() buttonStatus: 'ready' | 'disabled' | 'failed' = 'ready';
  @Output() onClick = new EventEmitter<void>();
  constructor() { }

  ngOnInit(): void {
  }

  emitClick() {
    if (this.buttonStatus !== 'disabled' && this.loading === false) {
      this.onClick.emit();
    }
  }
}
