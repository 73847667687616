import { Component } from '@angular/core';
import { LocationsService } from '@app/location/services/locations.service';
import { UserEventLoggerService } from '@app/shared/services/user-event-logger.service';

@Component({
  selector: 'kenjo-refresh-button',
  template: `
    <div class="hb-refresh-button" (click)="refresh()">
      <kenjo-icon>replay</kenjo-icon>
    </div>
  `,
  styleUrls: ['./refresh-button.component.scss']
})
export class RefreshButtonComponent {
  constructor(private locationService: LocationsService, private userEventLogger: UserEventLoggerService) { }

  refresh() {
    this.userEventLogger.logEvent('REFRESH_LOCATIONS');
    this.locationService.getAvailableLocations();
  }
}
