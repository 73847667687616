import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'hour' })
export class HourPipe implements PipeTransform {
  transform(value?: number): string {
    if (value === undefined) {
      return '--:--';
    }
    const hours = this.getHours(value);
    const hoursString = hours < 10 ? '0' + hours : `${hours}`;
    const minutes = Math.floor(value % 60);
    const minutesString = minutes < 10 ? '0' + minutes : `${minutes}`;
    return `${hoursString}:${minutesString}`;
  }

  getHours(nOfMinutes: number) {
    let hours = Math.floor(nOfMinutes / 60);
    const isOvernight = hours > 23;
    if (isOvernight) {
      hours = hours - 24;
    }
    return hours;
  }
}
