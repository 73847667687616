import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { KioskRoute } from '@app/shared/services/navigation.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ProgressService } from '../../services/progress.service';

@Component({
  selector: 'kenjo-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit, OnDestroy {
  currentProgress$: BehaviorSubject<number>;
  navigationSubscription: Subscription;
  hideWithoutAnimation = true;
  initialized = false;

  constructor(private progressService: ProgressService, private router: Router) { }

  ngOnInit(): void {
    this.currentProgress$ = this.progressService.currentProgress$;
    this.progressService.getProgress();
    this.navigationSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.initialized = true;
        this.progressService.updateProgress(event.url as KioskRoute);
      }
    });
  }

  ngOnDestroy(): void {
    this.navigationSubscription.unsubscribe();
  }

  setStep(step: number): void {
    this.progressService.setStep(step);
  }
}
