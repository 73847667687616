// Inspired by: https://christianliebel.com/2016/05/angular-2-a-simple-click-outside-directive/
import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[kenjoClickOutside]'
})
export class ClickOutsideDirective {
  @Output('kenjoClickOutside')
  public clickOutside: EventEmitter<void> = new EventEmitter<void>();

  @HostListener('document:click', ['$event.target'])
  public onClick(clickedElement: any): void {
    const clickedInside = this.elementRef.nativeElement.contains(clickedElement);
    if (!clickedInside) {
      this.clickOutside.emit();
    }
  }

  constructor(private elementRef: ElementRef) {}
}
