<div class="kenjo-row kenjo-justify-center" *ngIf="initialized">
  <div class="kenjo-row pb-container" [ngClass]="{ 'hidden': (currentProgress$ | async) < 1 || (currentProgress$ | async) > 4 }">
    <div class="pb-progress-step">
      <div [ngClass]="{'pb-active': (currentProgress$ | async) >= 1 }"></div>
    </div>
    <div class="pb-progress-step">
      <div [ngClass]="{'pb-active': (currentProgress$ | async) >= 2 }"></div>
    </div>
    <div class="pb-progress-step">
      <div [ngClass]="{'pb-active': (currentProgress$ | async) >= 3 }"></div>
    </div>
    <div class="pb-progress-step">
      <div [ngClass]="{'pb-active': (currentProgress$ | async) >= 4 }"></div>
    </div>
  </div>
</div>
