import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import * as Sentry from '@sentry/browser';
import { environment } from '../../../environments/environment';
import { Injectable, Injector } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { NavigationService } from './navigation.service';
import { TranslatePipe } from '../pipes/translate.pipe';
import { KioskSessionService } from '@app/check-in/services/kiosk-session.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  constructor(private snackBar: MatSnackBar, private injector: Injector) {
    if (environment.production === true) {
      const config = {
        dsn: environment.SENTRY_DSN,
        release: environment.SLUG_COMMIT_AWS,
        environment: environment.APP_NAME_AWS,
        integrations: [
          Sentry.dedupeIntegration(),
          Sentry.extraErrorDataIntegration({ depth: 5 }),
        ],
      };

      Sentry.init(config);
      Sentry.withScope((scope) => {
        scope.setTag('git_commit', environment.SLUG_COMMIT_AWS);
        scope.setTag('aws_app_name', environment.APP_NAME_AWS);
        scope.setTag('angular_production', `${environment.production}`);
      });
    }
  }

  displayError(errorMessage: string) {
    const snackBarRef = this.snackBar.open(errorMessage, 'x', {
      panelClass: 'kenjo-error-snackbar',
      duration: 5000,
    });
  }

  displayErrorCustom(errorMessage: string, closeSymbol?: string) {
    const snackBarRef = this.snackBar.open(errorMessage, closeSymbol, {
      panelClass: ['kenjo-error-snackbar', 'custom-snackbar'],
      duration: 5000,
    });
  }

  public logErrorForDevelopers(error: HttpErrorResponse): void {
    if (environment.production === true) {
      Sentry.withScope((scope) => {
        scope.setExtra('Original Error - Name', error.name);
        scope.setExtra('Original Error - Status', error.status);
        scope.setExtra('Original Error - Status text', error.statusText);
        scope.setExtra('Original Error - URL', error.url);
        scope.setExtra('Original Error - Message', error.message);
        scope.setExtra('Original Error - Ok', error.ok);
        scope.setExtra('Original Error - Error', error.error);
        Sentry.captureException(error);
      });
    }

    if (environment.ENABLE_ERRORS_BY_CONSOLE === 'true') {
      // tslint:disable: no-console
      console.log('');
      console.log('----- ERROR -----');
      const sentryMessage: string =
        environment.production === true
          ? `Sentry id: ${Sentry.lastEventId()}`
          : 'No Sentry';
      console.log(sentryMessage);
      console.log(error);
      console.log('-----------------');
      console.log('');
      // tslint:enable: no-console
    }
  }

  connectionErrorHandler(): void {
    const errorMessage = this.injector
      .get(TranslatePipe)
      .transform('connectivityIssue');
    this.injector.get(NavigationService).goToPinCode();
    this.injector.get(KioskSessionService).updateOnlineStatus(false);
    this.displayErrorCustom(errorMessage);
  }

  logAndDisplayUnrecognizedError(error: HttpErrorResponse): void {
    this.logErrorForDevelopers(error);
    this.displayError(
      this.injector
        .get(TranslatePipe)
        .transform('somethingWentWrongActionNotSaved')
    );
  }
}
