import { Component } from '@angular/core';
import { ProgressService } from '@app/shared/services/progress.service';

@Component({
  selector: 'kenjo-footer-bar',
  templateUrl: './footer-bar.component.html',
  styleUrls: ['./footer-bar.component.scss']
})
export class FooterBarComponent {
  currentProgress$ = this.progressService.currentProgress$;

  constructor(private progressService: ProgressService) { }
}
