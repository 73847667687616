import { Component, OnInit } from '@angular/core';
import { KioskSessionService } from '@app/check-in/services/kiosk-session.service';
import { ProgressService } from '@app/shared/services/progress.service';

@Component({
  selector: 'kenjo-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  kioskSession$ = this.kioskSessionService.kioskSession$;
  currentProgress$ = this.progressService.currentProgress$;
  documentElement: any;

  constructor(private kioskSessionService: KioskSessionService, private progressService: ProgressService) {}

  ngOnInit(): void {
    this.documentElement = document.documentElement;
  }
}
