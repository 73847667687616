import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EntryMethod } from '@app/check-in/model/kiosk-session.model';
import { BehaviorSubject } from 'rxjs';
import { KioskRoute } from './navigation.service';
import { PersistenceService } from './persistence.service';

@Injectable({ providedIn: 'root' })
export class ProgressService {
  currentProgress$ = new BehaviorSubject<number>(-1);
  routeProgressHandler = new RouteProgressHandler();

  constructor(private router: Router, private persistenceService: PersistenceService) { }

  updateProgress(route: KioskRoute) {
    this.setStep(this.routeProgressHandler.getRouteProgress(route));
  }

  setStep(step: number) {
    this.currentProgress$.next(step);
  }

  getPreviousRoute() {
    const currentProgress = this.currentProgress$.value;
    const prevRoute = this.routeProgressHandler.getRouteByProgress(currentProgress - 1);
    return prevRoute;
  }

  getLastVisitedRoute(): KioskRoute {
    const lastVisitedCookie = this.persistenceService.getItem('locationId');
    if (!lastVisitedCookie) {
      return '/locations';
    }

    const entryMethod = this.persistenceService.getItem('selectedEntryMethod') as EntryMethod;
    if (entryMethod === 'pinCode') {
      return '/check-in/pincode';
    }

    return '/check-in/qr';
  }

  getProgress() {
    return this.routeProgressHandler.getRouteProgress(this.router.url);
  }
}

class RouteProgressHandler {
  routesProgress: Record<KioskRoute, number> = {
    '/sign-in/email': -1,
    '/sign-in/code': 0,
    '/locations': 1,
    '/setup/message': 2,
    '/setup/theme': 3,
    '/setup/methods': 4,
    '/check-in/pincode': 5,
    '/check-in/qr': 5,
    '/check-in/user-attendance': 6
  }

  getRouteProgress(route: string): number {
    const routeProgress = this.routesProgress[route];
    return routeProgress !== undefined ? routeProgress : -1;
  }

  getRouteByProgress(progress: number): KioskRoute {
    const [routeName]: [KioskRoute, number] =
      (Object.entries(this.routesProgress) as Array<[KioskRoute, number]>).find(([_, iRouteProgress]) => {
        return iRouteProgress === progress;
      });
    return routeName;
  }
}
