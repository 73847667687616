import { Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TranslationService } from '../../services/translation.service';

@Component({
  selector: 'kenjo-language-picker',
  templateUrl: './language-picker.component.html',
  styleUrls: ['./language-picker.component.scss']
})
export class LanguagePickerComponent {
  constructor(public translationService: TranslationService) { }

  currentLanguage$ = this.translationService.currentLanguage$;
  i18n: BehaviorSubject<Record<string, string>> = this.translationService.currentTranslations$;
}
