import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'orgos-column-container',
  template: '<ng-content select="orgos-column, [orgosColumn]"></ng-content>',
  styleUrls: ['column-container.component.scss']
})
export class ColumnContainerComponent {
  @Input()
  set centerColumns(centerColumns: boolean | string) {
    if (centerColumns === true || centerColumns === 'true') {
      this.alignItemsStyle = 'center';
      return;
    }

    this.alignItemsStyle = '';
    return;
  }

  @Input()
  set wrap(wrap: boolean | string) {
    if (wrap === true || wrap === 'true') {
      this.flexWrapStyle = 'wrap';
      return;
    }

    this.flexWrapStyle = '';
    return;
  }

  @HostBinding('style.align-items') alignItemsStyle: string = '';
  @HostBinding('style.flex-wrap') flexWrapStyle: string = '';
}
