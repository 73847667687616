import { Injectable } from '@angular/core';

const persistenceKeys = [
  'language',
  'sessionId',
  'locationId',
  'refresh_token',
  'sessionKilled',
  'selectedEntryMethod',
  'welcomeMessage',
  'pinCodeActive',
  'qrCodeActive',
  'selectedThemeColor',
  'version',
  'accessToken',
  'userId',
  'name',
  'sessionNames'
] as const;
type PersistenceKey = (typeof persistenceKeys)[number];
@Injectable({ providedIn: 'root' })
export class PersistenceService {
  getItem(key: PersistenceKey) {
    return localStorage.getItem(key) ?? undefined;
  }
  setItem(key: PersistenceKey, value: string) {
    localStorage.setItem(key, value);
  }
  clearItem(key: PersistenceKey) {
    localStorage.removeItem(key);
  }
  clearAllData() {
    localStorage.clear();
  }
}
