import { BehaviorSubject } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { EntryMethod } from '@app/check-in/model/kiosk-session.model';
import { TranslatePipe } from '@app/shared/pipes/translate.pipe';
import { HttpClientService } from '@app/shared/services/http-client.service';
import { AuthService } from '@app/shared/services/auth.service';
import { ErrorService } from '@app/shared/services/error.service';
import { environment } from '@env';
import { KioskSessionService } from '@app/check-in/services/kiosk-session.service';
import { PersistenceService } from '@app/shared/services/persistence.service';
import { ThemeColorService } from '@app/shared/services/theme-color.service';

@Injectable({ providedIn: 'root' })
export class KioskSetupService {
  public welcomeMessage$ = new BehaviorSubject<string | null>(null);
  public orgAvailableMethods$ = new BehaviorSubject<
    Array<EntryMethod> | undefined
  >(undefined);
  public pinCodeActive$ = new BehaviorSubject<boolean>(true);
  public qrCodeActive$ = new BehaviorSubject<boolean>(true);
  public selectedLocationId$ = new BehaviorSubject<string | undefined>(
    undefined
  );
  public name$ = new BehaviorSubject<string | null>(null);
  public hasJustActivatedKiosk: boolean = false;
  public sessionNames: string[] = [];
  private ATTENDANCE_SETTINGS_URL = `${environment.PEOPLE_CLOUD_APP_URL}/attendance-settings-db`;

  constructor(private injector: Injector) {
    this.initValuesFromPersistence();
  }

  initValuesFromPersistence() {
    const persistence = this.injector.get(PersistenceService);
    const savedLocationId = persistence.getItem('locationId');
    const savedWelcomeMessage = persistence.getItem('welcomeMessage');
    const savedPinCodeActive = persistence.getItem('pinCodeActive');
    const savedQrCodeActive = persistence.getItem('qrCodeActive');

    if (savedLocationId !== undefined) {
      this.selectedLocationId$.next(savedLocationId);
    }

    if (savedWelcomeMessage !== undefined) {
      this.welcomeMessage$.next(savedWelcomeMessage);
    }

    if (savedPinCodeActive !== undefined) {
      this.pinCodeActive$.next(savedPinCodeActive === 'true');
    }

    if (savedQrCodeActive !== undefined) {
      this.qrCodeActive$.next(savedQrCodeActive === 'true');
    }
  }

  saveMethodsInitialState() {
    this.injector
      .get(PersistenceService)
      .setItem('pinCodeActive', `${this.pinCodeActive$.getValue()}`);
    this.injector
      .get(PersistenceService)
      .setItem('qrCodeActive', `${this.qrCodeActive$.getValue()}`);
  }

  async fetchOrgAvailableMethods() {
    Promise.resolve().then(() =>
      this.orgAvailableMethods$.next(['pinCode', 'qrCode'])
    );
  }

  setSelectedLocationId(value?: string) {
    this.selectedLocationId$.next(value);
    this.injector.get(PersistenceService).setItem('locationId', value);
  }

  setWelcomeMessage(value?: string) {
    this.welcomeMessage$.next(value);
    this.injector.get(PersistenceService).setItem('welcomeMessage', value);
  }

  setName(value?: string) {
    this.name$.next(value);
    this.injector.get(PersistenceService).setItem('name', value);
  }

  setPinCodeActive(value: boolean) {
    this.pinCodeActive$.next(value);
    this.injector.get(PersistenceService).setItem('pinCodeActive', `${value}`);
  }

  setQrCodeActive(value: boolean) {
    this.qrCodeActive$.next(value);
    this.injector.get(PersistenceService).setItem('qrCodeActive', `${value}`);
  }

  async activateKiosk() {
    const methods = new Array<EntryMethod>();
    if (this.pinCodeActive$.getValue()) {
      methods.push('pinCode');
    }
    if (this.qrCodeActive$.getValue()) {
      methods.push('qrCode');
    }

    const locationId = this.selectedLocationId$.getValue();
    const welcomeMessage = this.welcomeMessage$.getValue() || '';
    const name = this.name$.getValue() || null;
    const themeColor = this.injector
      .get(ThemeColorService)
      .selectedThemeColor$.getValue();
    const { error } = await this.injector
      .get(KioskSessionService)
      .createKioskSession(
        locationId,
        methods,
        welcomeMessage,
        themeColor,
        name
      );
    if (!error) {
      this.hasJustActivatedKiosk = true;
    }
    return { error };
  }

  getEntryMethods() {
    const result = new Array<EntryMethod>();
    if (this.pinCodeActive$.getValue()) {
      result.push('pinCode');
    }
    if (this.qrCodeActive$.getValue()) {
      result.push('qrCode');
    }
    return result;
  }
}
