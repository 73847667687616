import { Component } from '@angular/core';

@Component({
  selector: 'kenjo-mountain-illustration',
  template: `
    <svg width="225" height="224" viewBox="0 0 225 224" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M188.38 143.564C188.935 140.064 192.013 137.377 195.717 137.377C197.111 137.377 198.45 137.757 199.612 138.466C201.379 135.539 204.56 133.717 208.094 133.717C213.553 133.717 217.995 138.095 217.995 143.477C217.995 143.511 217.995 143.545 217.994 143.579C221.502 144.161 224.266 147.177 224.266 150.798C224.266 154.834 220.852 158.118 216.757 158.118H189.529C185.434 158.118 182.021 154.834 182.021 150.798C182.021 147.146 184.829 144.111 188.38 143.564Z" fill="#A6FFBC"/>
      <ellipse cx="111.055" cy="174.589" rx="89.2094" ry="30.3251" fill="#AAF2BC"/>
      <path d="M156.948 5.12305H110.055V27.8889H156.948L147.22 16.6033L156.948 5.12305Z" fill="white"/>
      <path d="M70.4425 60.3104C69.5244 54.518 64.4305 50.0722 58.3008 50.0722C55.9948 50.0722 53.7789 50.7012 51.857 51.8743C48.9331 47.0301 43.6692 44.0156 37.8213 44.0156C28.7876 44.0156 21.4378 51.2611 21.4378 60.1665C21.4378 60.2217 21.4378 60.2789 21.4398 60.3341C15.6339 61.2982 11.0615 66.2882 11.0615 72.2797C11.0615 78.9594 16.7099 84.3929 23.4857 84.3929H68.5405C75.3163 84.3929 80.9647 78.9594 80.9647 72.2797C80.9647 66.237 76.3183 61.2154 70.4425 60.3104Z" fill="#A6FFBC"/>
      <path d="M36.7683 131.669L5.4463 190.398H216.664L171.535 110.857L160.202 131.669L109.303 47.8008L49.5443 149.597L36.7683 131.669Z" fill="white"/>
      <path d="M127.657 189.858H215.702C216.592 189.858 217.151 188.899 216.713 188.125L188.84 138.898M127.657 189.858H71.8978M127.657 189.858L156.45 138.898M71.8978 189.858H5.07679C4.1938 189.858 3.63127 188.918 4.0586 188.145C7.2273 182.416 17.6943 163.579 25.1854 151.143M71.8978 189.858C71.8978 189.858 51.4371 155.484 49.429 151.923M25.1854 151.143C28.5097 144.495 33.801 135.382 35.8533 131.887C36.2376 131.233 37.1774 131.239 37.5642 131.892L49.429 151.923M25.1854 151.143V151.143C31.66 157.756 42.1592 158.191 49.1683 152.148L49.429 151.923M49.429 151.923L85.0328 89.5918M156.45 138.898L160.29 141.937C167.655 147.768 178.086 147.688 185.359 141.743C186.438 140.861 187.584 139.925 188.84 138.898M156.45 138.898L159.643 132.608M188.84 138.898L172.827 110.616C172.371 109.81 171.201 109.836 170.782 110.662L159.643 132.608M109.604 46.5755L134.623 89.5918M109.604 46.5755L85.0328 89.5918M109.604 46.5755V34.039V28.1644M159.643 132.608L134.623 89.5918M134.623 89.5918L121.389 99.1119C114.361 104.168 104.876 104.128 97.8913 99.0109L85.0328 89.5918M109.604 28.1644V11.4308V5.13545C109.604 4.49434 110.123 3.97461 110.765 3.97461L156.706 3.97461C157.758 3.97461 158.267 5.263 157.499 5.98261L147.41 15.4346C146.914 15.8992 146.922 16.6885 147.426 17.1437L157.4 26.1416C158.19 26.8538 157.686 28.1644 156.623 28.1644H109.604Z" stroke="#59E37C" stroke-width="3" stroke-linecap="round"/>
      <path d="M87.9209 7.90039V13.7784" stroke="#59E37C" stroke-width="1.66323" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M87.9209 21.6152V27.4932" stroke="#59E37C" stroke-width="1.66323" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M77.9834 17.6973H83.9461" stroke="#59E37C" stroke-width="1.66323" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M91.8965 17.6973H97.8591" stroke="#59E37C" stroke-width="1.66323" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M133.522 39.3477V44.0166" stroke="#59E37C" stroke-width="1.32113" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M133.522 50.2422V54.9112" stroke="#59E37C" stroke-width="1.32113" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M125.629 47.1289H130.365" stroke="#59E37C" stroke-width="1.32113" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M136.68 47.1289H141.416" stroke="#59E37C" stroke-width="1.32113" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M28.9166 112.49L26.5273 111.545L28.9166 110.587C30.439 109.984 31.8218 109.084 32.9818 107.941C34.1418 106.797 35.0548 105.434 35.6659 103.933L36.6242 101.578L37.5826 103.933C38.1896 105.437 39.1011 106.802 40.2616 107.946C41.422 109.09 42.8071 109.989 44.3319 110.587L46.7346 111.545L44.3319 112.49C42.8071 113.088 41.422 113.987 40.2616 115.131C39.1011 116.275 38.1896 117.64 37.5826 119.143L36.6242 121.512L35.6659 119.143C35.0548 117.642 34.1418 116.279 32.9818 115.136C31.8218 113.992 30.439 113.092 28.9166 112.49V112.49Z" stroke="#59E37C" stroke-width="2.25917" stroke-linejoin="round"/>
      <path d="M164.204 90.6147L161.814 89.6699L164.204 88.7118C165.726 88.1095 167.109 87.2094 168.269 86.0659C169.429 84.9225 170.342 83.5592 170.953 82.0584L171.911 79.7031L172.87 82.0584C173.477 83.5616 174.388 84.9269 175.549 86.0709C176.709 87.2149 178.094 88.1135 179.619 88.7118L182.022 89.6699L179.619 90.6147C178.094 91.213 176.709 92.1117 175.549 93.2557C174.388 94.3997 173.477 95.765 172.87 97.2681L171.911 99.6367L170.953 97.2681C170.342 95.7674 169.429 94.4041 168.269 93.2606C167.109 92.1172 165.726 91.2171 164.204 90.6147V90.6147Z" stroke="#59E37C" stroke-width="2.25917" stroke-linejoin="round"/>
    </svg>
  `,
  styleUrls: ['./illustration-styles.scss']
})
export class MountainIllustrationComponent { }
