import { Injectable } from '@angular/core';
import { HttpClientService } from '@app/shared/services/http-client.service';
import { BehaviorSubject } from 'rxjs';
import { IUserAttendance } from '../model/user-attendance.model';
import { IBreakReminderInfo } from '../model/break-reminder.model';
import { AuthService } from '@app/shared/services/auth.service';
import { environment } from '@env';
import { ErrorService } from '@app/shared/services/error.service';
import { TranslatePipe } from '@app/shared/pipes/translate.pipe';
import { IBreakReminderDuration } from '../components/break-reminder/break-reminder.component';

@Injectable({
  providedIn: 'root'
})
export class BreakReminderService {
  public breakReminderInfo$ = new BehaviorSubject<IBreakReminderInfo>({ openReminder: false });

  constructor(private http: HttpClientService, private auth: AuthService, private errorService: ErrorService, private translatePipe: TranslatePipe) { }

  async checkBreakReminder({ _userId, date }: IUserAttendance, todayAttendance: Array<IUserAttendance>) {
    const thereAreBreaksTodayAlready = todayAttendance.some(iEntry => !!iEntry.breaks?.length);
    if (thereAreBreaksTodayAlready) {
      return { openReminder: false }
    }
    const body = { _userId, date, interface: 'kiosk-pin-code' };
    const result = await this.http.post<IBreakReminderInfo>(`${environment.PEOPLE_CLOUD_APP_URL}/controller/attendance/break-reminder/get-configuration`, body, this.auth.getAuthHeader());
    this.breakReminderInfo$.next(result);
  }

  clearBreakReminder() {
    this.breakReminderInfo$.next({ openReminder: false });
  }

  async saveBreak(
    attendanceEntry: IUserAttendance,
    breakReminderDuration: IBreakReminderDuration,
    id: string
  ) {
    try {
      const userId = id;
      const date = attendanceEntry.date;
      const entryInterface = 'kiosk-pin-code';
      const { breakStart, breakEnd, total } = breakReminderDuration;
      const newBreaks = [{ start: breakStart, end: breakEnd }];
      const breaks = [...this.breakReminderInfo$.getValue().breaks, newBreaks[0]];
      const body = { _userId: userId, date, _interface: entryInterface, breaks, pinCode: breakReminderDuration.pincode };
      await this.http.post<IBreakReminderInfo>(`${environment.PEOPLE_CLOUD_APP_URL}/controller/attendance/break-reminder/distribute-breaks`, body, this.auth.getAuthHeader());
      const lastModifiedEntry = { ...attendanceEntry, breaks: newBreaks, breakTime: total };
      return lastModifiedEntry;
    } catch(e) {
      if (e.error === 'Break conflict') {
        this.errorService.displayError(this.translatePipe.transform('breakReminderBreakConflict'));
      } else if (e.error === 'Time off conflict') {
        this.errorService.displayError(this.translatePipe.transform('breakReminderTimeOffConflict'));
      } else {
        this.errorService.displayError(
          this.translatePipe.transform('somethingWentWrongActionNotSaved')
        );
      }

      if (e.status === 0) {
        this.errorService.connectionErrorHandler();
        return false;
      }
      this.errorService.logErrorForDevelopers(e);
      return false;
    }
  }
}
