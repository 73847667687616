import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'kenjo-hold-button',
  templateUrl: './hold-button.component.html',
  styleUrls: ['./hold-button.component.scss']
})
export class HoldButtonComponent {
  @Input() title: string;
  @Input() buttonId?: string = 'submit-button';
  @Input() flexWidth: boolean = false;
  @Input() size: 'large' | 'medium' = 'large';
  @Input() buttonStatus: 'ready' | 'loading' | 'disabled' | 'failed' = 'ready';
  @Input() disabled: boolean = false;

  @Output() onHoldSuccess = new EventEmitter<void>();

  totalTime = 900;
  progress = 0;

  holdHandler(numOfMS: number) {
    this.progress = (numOfMS / this.totalTime) * 100;
    if (this.progress === 100) {
      this.onHoldSuccess.emit();
    }
  }
}
