import { Component } from '@angular/core';

@Component({
  selector: 'kenjo-empty-location',
  template: `
    <svg width="128" height="131" viewBox="0 0 128 131" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_4_13667)">
      <path id="el-ground" d="M106.5 118.727C106.5 125.354 91.2777 130.727 72.5 130.727C53.7223 130.727 38.5 125.354 38.5 118.727C38.5 112.099 53.7223 106.727 72.5 106.727C91.2777 106.727 106.5 112.099 106.5 118.727Z" fill="#AAF2BC"/>
      <path id="el-cloud-1"  fill-rule="evenodd" clip-rule="evenodd" d="M11.8857 54.7266C5.59754 54.7266 0.5 49.553 0.5 43.171C0.5 36.7891 5.59754 31.6155 11.8857 31.6155C11.9548 31.6155 12.0132 31.5634 12.0214 31.4948C13.0847 22.6029 20.2649 15.7266 28.9642 15.7266C37.43 15.7266 44.4572 22.2387 45.8082 30.7832C46.0132 32.0794 47.0438 33.1177 48.2937 33.5173C52.8625 34.9783 56.5 39.7529 56.5 44.7022C56.5 49.4191 53.0632 54.7266 48.9917 54.7266H11.8857Z" fill="#A6FFBC"/>
      <path id="el-marker-1" fill-rule="evenodd" clip-rule="evenodd" d="M76.8348 116.413C76.5628 116.75 76.2566 117.057 75.9212 117.331C73.251 119.505 69.3308 119.094 67.1652 116.413C42.7217 86.1533 30.5 63.8134 30.5 49.3934C30.5 26.3814 49.0802 7.72659 72 7.72659C94.9198 7.72659 113.5 26.3814 113.5 49.3934C113.5 63.8134 101.278 86.1533 76.8348 116.413Z" fill="white"/>
      <path id="el-marker-2" fill-rule="evenodd" clip-rule="evenodd" d="M73.9023 114.851C74.0656 114.718 74.2148 114.569 74.3476 114.404C86.5051 99.3538 95.5439 86.3685 101.529 75.4288C107.552 64.419 110.303 55.7993 110.303 49.3934C110.303 28.1352 93.1419 10.9239 72 10.9239C50.8581 10.9239 33.6973 28.1352 33.6973 49.3934C33.6973 55.7993 36.448 64.419 42.4713 75.4288C48.4561 86.3685 57.4949 99.3538 69.6524 114.404C70.7069 115.71 72.6072 115.906 73.9023 114.851ZM75.9212 117.331C76.2566 117.057 76.5628 116.75 76.8348 116.413C101.278 86.1533 113.5 63.8134 113.5 49.3934C113.5 26.3814 94.9198 7.72659 72 7.72659C49.0802 7.72659 30.5 26.3814 30.5 49.3934C30.5 63.8134 42.7217 86.1533 67.1652 116.413C69.3308 119.094 73.251 119.505 75.9212 117.331Z" fill="#59E37C"/>
      <path id="el-circle-1" fill-rule="evenodd" clip-rule="evenodd" d="M72.5 69.7266C84.098 69.7266 93.5 60.5484 93.5 49.2266C93.5 37.9048 84.098 28.7266 72.5 28.7266C60.902 28.7266 51.5 37.9048 51.5 49.2266C51.5 54.0362 53.1967 58.4589 56.0376 61.9555C57.4593 63.7052 59.1674 65.223 61.0928 66.4414C64.3774 68.5197 68.2943 69.7266 72.5 69.7266Z" fill="#E5FBEB"/>
      <path id="el-circle-2" fill-rule="evenodd" clip-rule="evenodd" d="M72.5 66.5293C82.4052 66.5293 90.3027 58.7104 90.3027 49.2266C90.3027 39.7428 82.4052 31.9239 72.5 31.9239C62.5948 31.9239 54.6973 39.7428 54.6973 49.2266C54.6973 53.2684 56.1196 56.9861 58.5191 59.9394C59.7213 61.419 61.1684 62.7056 62.8024 63.7396C65.5871 65.5016 68.9132 66.5293 72.5 66.5293ZM93.5 49.2266C93.5 60.5484 84.098 69.7266 72.5 69.7266C68.2943 69.7266 64.3774 68.5197 61.0928 66.4414C59.1674 65.223 57.4593 63.7052 56.0376 61.9555C53.1967 58.4589 51.5 54.0362 51.5 49.2266C51.5 37.9048 60.902 28.7266 72.5 28.7266C84.098 28.7266 93.5 37.9048 93.5 49.2266Z" fill="#59E37C"/>
      <path id="el-star-1"   fill-rule="evenodd" clip-rule="evenodd" d="M117.486 1.64258C118.137 1.64258 118.723 2.04176 118.965 2.65053L119.364 3.65455C120.369 6.1805 122.358 8.18199 124.868 9.19254L125.866 9.59422C126.471 9.83777 126.867 10.4274 126.867 11.0831C126.867 11.7388 126.471 12.3284 125.866 12.572L124.868 12.9737C122.358 13.9842 120.369 15.9857 119.364 18.5116L118.965 19.5157C118.723 20.1244 118.137 20.5236 117.486 20.5236C116.834 20.5236 116.248 20.1244 116.006 19.5157L115.607 18.5116C114.603 15.9857 112.613 13.9842 110.103 12.9737L109.105 12.572C108.5 12.3284 108.104 11.7388 108.104 11.0831C108.104 10.4274 108.5 9.83777 109.105 9.59422L110.103 9.19254C112.613 8.18199 114.603 6.1805 115.607 3.65455L116.006 2.65053C116.248 2.04176 116.834 1.64258 117.486 1.64258ZM117.486 6.97745C116.447 8.63452 115.052 10.0377 113.405 11.0831C115.052 12.1285 116.447 13.5317 117.486 15.1888C118.524 13.5317 119.919 12.1285 121.566 11.0831C119.919 10.0377 118.524 8.63452 117.486 6.97745Z" fill="#59E37C"/>
      <path id="el-star-2"   fill-rule="evenodd" clip-rule="evenodd" d="M27.2529 86.3926C27.9045 86.3926 28.4905 86.7918 28.7326 87.4005L29.1317 88.4046C30.136 90.9305 32.1251 92.932 34.6353 93.9425L35.6331 94.3442C36.2381 94.5878 36.6348 95.1774 36.6348 95.8331C36.6348 96.4888 36.2381 97.0784 35.6331 97.322L34.6353 97.7237C32.1251 98.7342 30.136 100.736 29.1317 103.262L28.7326 104.266C28.4905 104.874 27.9045 105.274 27.2529 105.274C26.6013 105.274 26.0153 104.874 25.7733 104.266L25.3741 103.262C24.3698 100.736 22.3808 98.7342 19.8705 97.7237L18.8727 97.322C18.2677 97.0784 17.871 96.4888 17.871 95.8331C17.871 95.1774 18.2677 94.5878 18.8727 94.3442L19.8705 93.9425C22.3808 92.932 24.3698 90.9305 25.3741 88.4046L25.7733 87.4005C26.0153 86.7918 26.6013 86.3926 27.2529 86.3926ZM27.2529 91.7275C26.2141 93.3845 24.8196 94.7878 23.1728 95.8331C24.8196 96.8785 26.2141 98.2817 27.2529 99.9388C28.2918 98.2817 29.6863 96.8785 31.3331 95.8331C29.6863 94.7878 28.2918 93.3845 27.2529 91.7275Z" fill="#59E37C"/>
      <path id="el-cloud-2"  fill-rule="evenodd" clip-rule="evenodd" d="M95.5556 98.7266C95.2746 98.7266 94.8359 98.7266 94.8359 98.7266C94.8359 98.7266 94.8359 98.7266 94.8359 98.7266C90.2321 98.7266 86.5 94.8795 86.5 90.134C86.5 85.3884 90.2321 81.5414 94.8359 81.5414V81.5414C94.8865 81.5414 94.9293 81.5033 94.9352 81.453C95.7131 74.8404 100.97 69.7266 107.34 69.7266C113.718 69.7266 118.981 74.8547 119.748 81.4806C119.823 82.1321 120.337 82.6539 120.974 82.8088C124.558 83.6797 127.5 87.4044 127.5 91.2726C127.5 94.78 124.984 98.7266 122.003 98.7266H101.274" fill="#A6FFBC"/>
      </g>
      <defs>
      <clipPath id="clip0_4_13667">
      <rect width="127" height="131" fill="white" transform="translate(0.5)"/>
      </clipPath>
      </defs>
    </svg>
  `,
  styleUrls: ['./empty-location.component.scss']
})
export class EmptyLocationComponent {}
