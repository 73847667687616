import {
  Component,
  Inject,
  Injector,
  OnInit,
  Optional,
} from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { IAttendanceBreak } from '@app/check-in/model/user-attendance.model';
import { KickOutTimerService } from '@app/check-in/services/kick-out-timer.service';
import { IAttendancePolicy } from '@app/setup/model/attendance-settings.model';
import { DurationPipe } from '@app/shared/pipes/duration.pipe';
import { TranslateWithDataPipe } from '@app/shared/pipes/translate-with-data.pipe';
import { TranslatePipe } from '@app/shared/pipes/translate.pipe';

@Component({
  selector: 'kenjo-auto-deduct-dialog',
  templateUrl: 'auto-deduct-dialog.component.html',
  styleUrls: ['auto-deduct-dialog.component.scss'],
})
export class AutoDeductDialogComponent implements OnInit {
  translations: any;
  titleText: string;
  subtitleText: string;
  confirmButtonText: string;
  cancelButtonText: string;
  confirmButtonColor: 'Success' | 'Warning' | 'Danger' | 'Neutral' | 'Primary' =
    'Success';
  confirmButtonId: string;
  cancelButtonId: string;
  componentId: string;
  confirmDisabled: boolean;
  cancelDisabled: boolean;
  bullets: Array<{ text: string }>;
  attendancePolicy: IAttendancePolicy;
  break: IAttendanceBreak;
  tooltipText: string = '';
  isPastAutoDeduct: boolean;

  constructor(
    public dialogRef: MatDialogRef<AutoDeductDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private injector: Injector,
    private durationPipe: DurationPipe,
    private translateWithDataPipe: TranslateWithDataPipe,
    private kickOutService: KickOutTimerService
  ) {
    this.cancelButtonText = this.data.cancelButtonText;
    this.confirmButtonColor = this.data.confirmButtonColor
      ? this.data.confirmButtonColor
      : this.confirmButtonColor;
    this.confirmButtonId = this.data.confirmButtonId ?? '';
    this.cancelButtonId = this.data.cancelButtonId ?? '';
    this.componentId = this.data.componentId ?? null;
    this.confirmDisabled = this.data.confirmDisabled ?? false;
    this.cancelDisabled = this.data.cancelDisabled ?? false;
    this.dialogRef.disableClose = !!this.data.disableClose;
    this.attendancePolicy = this.data.attendancePolicy;
    this.break = this.data.break;
  }

  async ngOnInit(): Promise<void> {
    this.kickOutService.restartTimeout();
    this.translations = {
      autoDeductDialog: this.injector
        .get(TranslatePipe)
        .transform('autoDeductDialog'),
    };
    this.titleText = this.translations.autoDeductDialog.title;
    this.subtitleText = this.translations.autoDeductDialog.subtitle;
    this.confirmButtonText = this.translations.autoDeductDialog.confirm;
    this.checkIsPastAutoDeduct();
    this.initTranslations();
  }

  closeDialog(): void {
    this.kickOutService.restartTimeout();
    this.dialogRef.close(false);
  }

  confirmDialog(): void {
    this.kickOutService.restartTimeout();
    if (this.data.deleteProtection) {
      this.dialogRef.close(false);
      return;
    }
    this.dialogRef.close(true);
  }

  private formatReminder(reminder): string {
    const data = {
      triggeredAfter: this.durationPipe.transform(reminder?.triggeredAfter),
      suggestedBreak: this.durationPipe.transform(reminder?.suggestedBreak),
    };
    const message1 = this.translateWithDataPipe.transform(
      'breakReminderMessage1',
      data
    );
    const message2 = this.translateWithDataPipe.transform(
      'breakReminderMessage2',
      data
    );
    return message1 + message2;
  }

  initTranslations() {
    const { reminders } = this.attendancePolicy?.breakReminder;
    const tooltipReminders = reminders.map((iReminder) =>
      this.formatReminder(iReminder)
    );

    const mainBreakData = {
      triggeredAfter: this.durationPipe.transform(this.break?.triggeredAfter),
      suggestedBreak: this.durationPipe.transform(this.break?.suggestedBreak),
    };
    const mainMessage1 = this.translateWithDataPipe.transform(
      'breakReminderMessage1',
      mainBreakData
    );
    const mainMessage2 = this.translateWithDataPipe.transform(
      'breakReminderMessage2',
      mainBreakData
    );

    this.bullets = [
      {
        text: mainMessage1 + mainMessage2,
      },
    ];

    this.tooltipText = `${
      this.translations.autoDeductDialog.tooltip
    } ${tooltipReminders.join(', ')}.`;
  }

  checkIsPastAutoDeduct() {
    const { breakReminder } = this.attendancePolicy;

    let isPast = true;

    for (let index = 0; index < breakReminder?.reminders?.length; index++) {
      const reminder = breakReminder?.reminders[index];
      if (
        reminder?.suggestedBreak === this.break?.suggestedBreak &&
        reminder?.triggeredAfter === this.break?.triggeredAfter
      ) {
        isPast = false;
        break;
      }
    }
    this.isPastAutoDeduct = isPast;
  }
}

export interface IConfirmDialogData {
  titleText: string;
  subtitleText: string;
  confirmButtonText: string;
  confirmButtonColor: 'Success' | 'Warning' | 'Danger' | 'Neutral' | 'Primary';
  cancelButtonText: string;
}
