import { Component, Inject, NgZone, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'kenjo-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {
  titleText: string;
  subtitleText: string;
  confirmButtonText: string;
  cancelButtonText: string;
  confirmButtonColor: 'Success' | 'Warning' | 'Danger' | 'Neutral' = 'Success';
  confirmButtonId: string;
  cancelButtonId: string;
  componentId: string;

  constructor(public dialogRef: MatDialogRef<ConfirmationDialogComponent>, @Optional() @Inject(MAT_DIALOG_DATA) public data: any, private ngZone: NgZone) {
    this.titleText = this.data.titleText;
    this.subtitleText = this.data.subtitleText;
    this.confirmButtonText = this.data.confirmButtonText;
    this.cancelButtonText = this.data.cancelButtonText;
    this.confirmButtonColor = this.data.confirmButtonColor ? this.data.confirmButtonColor : this.confirmButtonColor;
    this.confirmButtonId = this.data.confirmButtonId ?? '';
    this.cancelButtonId = this.data.cancelButtonId ?? '';
    this.componentId = this.data.componentId ?? null;
  }

  goBack(): void {
    this.closeDialog(false);
  }

  confirmDialog(): void {
    if (this.data.deleteProtection) {
      this.closeDialog(false);
      return;
    }
    this.closeDialog(true);
  }

  closeDialog(result: boolean) {
    this.ngZone.run(() => {
      this.dialogRef.close(result);
    });
  }
}
