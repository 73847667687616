<div class="main-container">
  <kenjo-sidebar></kenjo-sidebar>
  <div
    *ngIf="guardLoading$ | async"
    class="kenjo-column content-container loading-container kenjo-align-center kenjo-justify-center"
  >
    <kenjo-loading-spinner color="green"></kenjo-loading-spinner>
  </div>
  <div
    class="kenjo-column content-container"
    [ngClass]="{ 'content-hidden': guardLoading$ | async }"
  >
    <kenjo-header-bar></kenjo-header-bar>
    <router-outlet></router-outlet>
    <kenjo-footer-bar></kenjo-footer-bar>
  </div>
</div>
