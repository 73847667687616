import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClientService } from './http-client.service';
import { InternationalizationService } from './internationalization.service';
import { en, es, de } from '../../i18n/i18n';
import { PersistenceService } from './persistence.service';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  languagesMap = { en, es, de };

  currentLanguage$: BehaviorSubject<keyof typeof this.languagesMap> = new BehaviorSubject('en');
  currentTranslations$: BehaviorSubject<Record<string, string>> = new BehaviorSubject(this.languagesMap.en);

  constructor(private httpClient: HttpClientService, private internationalizationService: InternationalizationService, private persistence: PersistenceService) {
    this.initLocale(); // Set it right away for the date pipe
    this.initLanguage();
  }

  initLocale() {
    this.internationalizationService.setUserLocale(this.getBrowserLanguage());
  }

  async initLanguage() {
    const persistedLanguage = await this.persistence.getItem('language');
    if (persistedLanguage) {
      this.setLanguage(persistedLanguage, true);
      return;
    }

    this.setLanguage(this.getBrowserLanguage());
  }

  getBrowserLanguage() {
    const [lang, country] = navigator.language.split('-');
    if (lang in this.languagesMap) {
      return lang;
    }
    return 'en';
  }

  async setLanguage(lang: string, skipPersistence?: boolean) {
    if (!(lang in this.languagesMap)) {
      lang = 'en';
    }

    this.currentLanguage$.next(lang as keyof typeof this.languagesMap);
    this.currentTranslations$.next(this.languagesMap[lang]);
    this.internationalizationService.setUserLocale(lang);

    if (!skipPersistence) {
      await this.persistence.setItem('language', lang);
    }
  }
}
