import { Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'kenjo-success-screen-container',
  template: `
    <div #container class="ssc-bubble" [ngClass]="{'ssc-bubble-show': show}" (click)="show = false"></div>
    <div class="ssc-content" [ngClass]="{'ssc-content-show': show}">
      <ng-content></ng-content>
    </div>
  `,
  styleUrls: ['./success-screen-container.component.scss']
})
export class SuccessScreenContainerComponent {
  @ViewChild('container', { read: ElementRef }) containerElementRef: ElementRef;
  @Input() originElementId?: string;
  @Input() brokenAttendanceLimit: 'breakReminder' | 'shiftDuration';
  @Input() set show(value: boolean) {
    // Workaround to Overlay CDK not animating on show
    setTimeout(() => this._show = value, 0);
    if (value === true) {
      this.setOriginOffset();
    }
  }
  get show() { return this._show; }

  _show: boolean = false;
  containerElement: HTMLElement;

  setOriginOffset() {
    const originElement = document.getElementById(this.originElementId);
    this.containerElementRef.nativeElement.style.top = `${originElement?.offsetTop ?? 0}px`;
    this.containerElementRef.nativeElement.style.left = `${originElement?.offsetLeft ?? 0}px`;
  }
}
