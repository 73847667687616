import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TranslatePipe } from '@app/shared/pipes/translate.pipe';
import { AuthService } from '@app/shared/services/auth.service';
import { ErrorService } from '@app/shared/services/error.service';
import { HttpClientService } from '@app/shared/services/http-client.service';
import { NavigationService } from '@app/shared/services/navigation.service';
import { PersistenceService } from '@app/shared/services/persistence.service';
import { environment } from '@env';
import { EntryMethod, IKioskSession } from '../model/kiosk-session.model';
import { ThemeColorService } from '@app/shared/services/theme-color.service';
import * as dayjs from 'dayjs';

declare const window: any;

@Injectable({
  providedIn: 'root',
})
export class KioskSessionService {
  public qrCodeHash$ = new BehaviorSubject<string | undefined>(undefined);
  public sessionAvailableMethods$ = new BehaviorSubject<Array<EntryMethod>>([]);
  public welcomeMessage$ = new BehaviorSubject<string | undefined>(undefined);
  public imageUrl$ = new BehaviorSubject<string | undefined>(undefined);
  public selectedEntryMethod: EntryMethod;
  public kioskSession$ = new BehaviorSubject<IKioskSession | undefined>(
    undefined
  );

  private checkInterval: any;
  private timeOutRef: any;

  private connectionStatusSubject = new BehaviorSubject<boolean>(true);
  public connectionStatus$ = this.connectionStatusSubject.asObservable();

  constructor(
    private injector: Injector,
    private errorService: ErrorService,
    private persistenceService: PersistenceService
  ) {}

  public updateOnlineStatus(isOnline: boolean) {
    this.connectionStatusSubject.next(isOnline);
  }

  async createKioskSession(
    locationId: string,
    entryMethods: Array<EntryMethod>,
    welcomeMessage: string,
    themeColor?: string,
    name?: string
  ) {
    try {
      const currentDate = dayjs();
      const expirationDate = currentDate.add(1, 'year');

      const session = await this.injector
        .get(HttpClientService)
        .post<IKioskSession>(
          `${environment.PEOPLE_CLOUD_APP_URL}/attendance-kiosk-session-db/`,
          {
            locationId,
            entryMethods,
            welcomeMessage,
            themeColor,
            name,
            expirationDate,
          },
          this.injector.get(AuthService).getAuthHeader()
        );

      this.kioskSession$.next(session);
      this.clearPreLaunchPersistence();
      this.injector.get(PersistenceService).setItem('sessionId', session._id);
      return { error: false };
    } catch (error) {
      this.injector
        .get(ErrorService)
        .displayError(
          this.injector
            .get(TranslatePipe)
            .transform('couldNotActivateKioskErrorMessage')
        );
      this.errorService.logErrorForDevelopers(error);
      return { error: true };
    }
  }

  clearPreLaunchPersistence() {
    this.injector.get(PersistenceService).clearItem('sessionId');
    this.injector.get(PersistenceService).clearItem('welcomeMessage');
    this.injector.get(PersistenceService).clearItem('locationId');
    this.injector.get(PersistenceService).clearItem('selectedThemeColor');
    this.injector.get(PersistenceService).clearItem('pinCodeActive');
    this.injector.get(PersistenceService).clearItem('qrCodeActive');
  }

  async initCheckKioskStatusInterval() {
    clearTimeout(this.checkInterval);
    await this.checkKioskStatusInInterval();
  }

  async checkKioskStatusInInterval() {
    clearTimeout(this.timeOutRef);
    clearTimeout(this.checkInterval);
    try {
      const session = this.getCurrentSession();
      if (session === undefined) {
        this.injector.get(NavigationService).goToLogIn();
      }

      let kioskSession = {} as IKioskSession;
      try {
        kioskSession = await this.injector
          .get(HttpClientService)
          .get<IKioskSession>(
            `${environment.PEOPLE_CLOUD_APP_URL}/controller/kiosk/status/${session._id}/${this.selectedEntryMethod}`,
            this.injector.get(AuthService).getAuthHeader()
          );

        if (kioskSession.token) {
          this.persistenceService.setItem('accessToken', kioskSession.token);
          this.persistenceService.setItem('userId', kioskSession.userId);
          this.reloadApp();
        }

        this.checkKioskVersion(kioskSession.version);
      } catch (networkError) {
        this.errorService.logErrorForDevelopers(networkError);
        const qrHasBeenDisabled =
          networkError?.error?.errorKey === 'qrCodeNotEnabled';
        if (qrHasBeenDisabled) {
          kioskSession = await this.injector
            .get(HttpClientService)
            .get<IKioskSession>(
              `${environment.PEOPLE_CLOUD_APP_URL}/controller/kiosk/status/${session._id}/pincode`,
              this.injector.get(AuthService).getAuthHeader()
            );
        } else {
          throw networkError;
        }
      }

      this.kioskSession$.next(kioskSession);
      if (kioskSession.themeColor) {
        this.injector
          .get(ThemeColorService)
          .setSelectedThemeColor(kioskSession.themeColor);
        this.injector.get(ThemeColorService).applyTheme();
      }
      this.sessionAvailableMethods$.next(kioskSession.entryMethods);
      const noMethodsAvailable = this.checkEntryMethods(
        kioskSession.entryMethods
      );
      if (noMethodsAvailable) {
        return;
      }

      this.emitNewQRHash(kioskSession);

      const nextCheckInSeconds = kioskSession.expiresInSeconds
        ? kioskSession.expiresInSeconds + 2
        : 30;
      this.checkInterval = setTimeout(() => {
        this.checkKioskStatusInInterval();
      }, nextCheckInSeconds * 1000);
      this.updateOnlineStatus(true);
    } catch (error) {
      this.errorService.logErrorForDevelopers(error);
      const kioskWasClosed = error?.status === 410;
      if (kioskWasClosed) {
        this.closeKiosk();
        return;
      }

      this.timeOutRef = setTimeout(() => {
        this.checkKioskStatusInInterval();
      }, 40000);

      if (error.status === 0) {
        this.updateOnlineStatus(false);
      } else {
        this.injector
          .get(ErrorService)
          .displayError(
            this.injector.get(TranslatePipe).transform('somethingWentWrong')
          );
      }
    }
  }

  setEntryMethod(entryMethod: EntryMethod) {
    this.selectedEntryMethod = entryMethod;
    this.injector
      .get(PersistenceService)
      .setItem('selectedEntryMethod', entryMethod);
  }

  getCurrentSession() {
    const currentOne = this.kioskSession$.getValue();
    if (currentOne !== undefined) {
      return currentOne;
    }
    const storedSessionId = this.injector
      .get(PersistenceService)
      .getItem('sessionId');
    if (storedSessionId === undefined) {
      return undefined;
    }
    return { _id: storedSessionId } as IKioskSession;
  }

  emitNewQRHash(kioskStatus: IKioskSession) {
    if (
      kioskStatus._qrHash !== undefined &&
      this.selectedEntryMethod === 'qrCode'
    ) {
      this.qrCodeHash$.next(kioskStatus._qrHash);
    }
  }

  checkEntryMethods(entryMethods: Array<EntryMethod>) {
    if (entryMethods.length === 0) {
      this.goBackToLocations();
      return true;
    }

    if (entryMethods.includes(this.selectedEntryMethod)) {
      return false;
    }

    this.injector.get(NavigationService).goToCheckIn(entryMethods);
    return false;
  }

  goBackToLocations() {
    this.clearSession();
    this.injector.get(NavigationService).goToLocations();
  }

  closeKiosk() {
    this.clearSession();
    this.injector.get(AuthService).killKioskSession();
  }

  clearSession() {
    this.qrCodeHash$.next(undefined);
    this.kioskSession$.next(undefined);
    this.selectedEntryMethod = undefined;

    this.injector.get(PersistenceService).clearItem('selectedEntryMethod');
    this.injector.get(PersistenceService).clearItem('locationId');
    this.injector.get(PersistenceService).clearItem('sessionId');

    this.injector.get(ThemeColorService).clearSelectedThemeColor();
    this.injector.get(ThemeColorService).restoreTheme();
  }

  checkKioskVersion(version: string) {
    if (!version) {
      return;
    }

    const currentVersion = this.injector
      .get(PersistenceService)
      .getItem('version');
    if (!currentVersion || Number(currentVersion) < Number(version)) {
      this.injector
        .get(PersistenceService)
        .setItem('version', version.toString());
      this.reloadApp();
    }
  }

  reloadApp() {
    window.location.reload(true);
  }
}
