import { Injectable } from '@angular/core';
import localeDe from '@angular/common/locales/de';
import localeDeAT from '@angular/common/locales/de-AT';
import localeEnUS from '@angular/common/locales/en';
import localeEnGB from '@angular/common/locales/en-GB';
import localeEs from '@angular/common/locales/es';
import localeEsMX from '@angular/common/locales/es-MX';

import { registerLocaleData } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class InternationalizationService {
  localeToUse: string;

  constructor() {}

  setUserLocale(userLocale: string = 'en-GB'): void {
    if (userLocale === 'de') {
      registerLocaleData(localeDe, 'kenjo-locale');
      this.localeToUse = userLocale;
    } else if (userLocale === 'de-AT') {
      registerLocaleData(localeDeAT, 'kenjo-locale');
      this.localeToUse = userLocale;
    } else if (userLocale === 'es') {
      registerLocaleData(localeEs, 'kenjo-locale');
      this.localeToUse = userLocale;
    } else if (userLocale === 'es-MX') {
      registerLocaleData(localeEsMX, 'kenjo-locale');
      this.localeToUse = userLocale;
    } else if (userLocale === 'en-US') {
      registerLocaleData(localeEnUS, 'kenjo-locale');
      this.localeToUse = userLocale;
    } else {
      registerLocaleData(localeEnGB, 'kenjo-locale');
      this.localeToUse = 'en-GB';
    }
  }
}
