<div class="kenjo-row kenjo-justify-space-between dt-container" justifyContent="space-between">
  <div class="kenjo-row kenjo-align-end">
    <div class="dt-hour kenjo-mr-20px">{{ date | date:'HH:mm' }}</div>
  </div>
  <div class="kenjo-row">
    <div class="dt-separator kenjo-mr-20px"></div>
    <div class="kenjo-column kenjo-justify-space-between dt-date" style="height: 64px;">
      <div>{{ date | date:'EEEE' }}</div>
      <div>{{ date | date:'dd MMMM' }}</div>
    </div>
  </div>
</div>
