import { Component } from '@angular/core';
import { NavigationService } from '@app/shared/services/navigation.service';

@Component({
  selector: 'kenjo-back-arrow-button',
  template:`
    <div class="hb-back-button" (click)="goBack()">
      <kenjo-icon>arrow_back</kenjo-icon>
    </div>
  `,
  styleUrls: ['./back-arrow-button.component.scss']
})
export class BackArrowButtonComponent {
  constructor(private navigationService: NavigationService) { }

  goBack() {
    this.navigationService.goBack();
  }
}
