import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'kenjo-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss']
})
export class UserAvatarComponent implements OnInit {
  @Input() photoUrl?: string;
  @Input() displayName?: string;
  @Input() size: 'large' | 'medium' | 'small' = 'large';
  nameInitials: string;

  constructor() { }

  ngOnInit(): void {
    this.nameInitials = this.calculateInitials(this.displayName);
  }

  private calculateInitials(name: string): string {
    if (name === undefined) {
      return '-';
    }

    const avatarName = name.trim();

    if (avatarName === '') {
      return '-';
    }

    const words = avatarName.trim().split(' ');
    if (words.length < 2) {
      return `${avatarName.charAt(0)}${avatarName.charAt(0)}`.toUpperCase();
    }

    if (words[0] === '+') {
      // If the name is a number (EG +22), use the whole word -> +22
      return `${words[0].trim()}${words[1].trim()}`;
    } else {
      // If the name is a proper name (EG John Smith). use the initials -> JS
      return `${words[0].trim().charAt(0)}${words[1].trim().charAt(0)}`.toUpperCase();
    }
  }

}
