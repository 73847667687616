import { Component } from '@angular/core';
import { ChildrenOutletContexts } from '@angular/router';
import { GuardLoadingService } from './shared/services/guard-loading.service';
import { ThemeColorService } from './shared/services/theme-color.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'kenjo-kiosk';
  guardLoading$ = this.guardLoadingService.guardLoading$;
  constructor(private contexts: ChildrenOutletContexts, private guardLoadingService: GuardLoadingService, private colorThemeService: ThemeColorService) {
    this.colorThemeService.applyStoredColor();
  }

  getRouteAnimationData() {
    return this.contexts.getContext('primary')?.route?.snapshot?.data?.animation;
  }
}
