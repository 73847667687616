import {ErrorHandler, Injectable} from '@angular/core';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  handleError(error: any): void {
   const chunkFailedMessage = /Loading chunk [\d]+ failed/;

    if (chunkFailedMessage.test(error.message)) {
      Promise.all(caches.keys() as any)
        .then(cacheKeys => {
          cacheKeys.map(caches.delete);
          window.location.reload();
        })
    }

    console.error(error);
  }
}
