<kenjo-dialog-container [title]="titleText" (clickCloseButton)="goBack()" [attr.id]="componentId !== null ? componentId : null">
  <div *ngIf="subtitleText" class="kenjo-font-color-light-text-757575 cd-white-space">
    {{ subtitleText }}
  </div>

  <div class="kenjo-mt-40px cd-buttons-row">
    <kenjo-raised-button *ngIf="cancelButtonText" color="Neutral" (click)="goBack()" class="cd-close cd-flex-shrink">{{ cancelButtonText }}</kenjo-raised-button>
    <div *ngIf="cancelButtonText && confirmButtonText" class="cd-button-separator"></div>
    <kenjo-raised-button *ngIf="confirmButtonText" [color]="confirmButtonColor" (click)="confirmDialog()" class="cd-close cd-flex-shrink">{{ confirmButtonText }}</kenjo-raised-button>
  </div>
</kenjo-dialog-container>
