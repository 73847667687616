import { Injectable, Injector } from '@angular/core';
import { environment } from '@env';
import * as amplitude from 'amplitude-js';

import { AuthService } from './auth.service';
import { HttpClientService } from './http-client.service';

type amplitudeEventKey = keyof typeof amplitudeEventKeys;
type amplitudeEventName = typeof amplitudeEventKeys[amplitudeEventKey];

@Injectable({ providedIn: 'root' })
export class UserEventLoggerService {
  private API_KEY = environment.AMPLITUDE_API_KEY;
  private loggedUser: { userId?: string, orgId?: string, email?: string };
  private ignoreAccount: boolean;
  private client: amplitude.AmplitudeClient;
  private organization: { brandName: string, _id: string, email: string, isFreeTrialAccount: boolean };
  private customerStatus: 'Customer' | 'Free trial';

  constructor(private injector: Injector) { }

  public async logEvent(eventKey: amplitudeEventKey, email?: string) {
    try {
      if (this.ignoreAccount || !this.API_KEY?.length) {
        return;
      }

      if (!this.client) {
        await this.initialize();
      }

      if (!this.client) {
        return;
      }

      const userProperties = {
        email: email ?? this.loggedUser.email,
        customerStatus: this.customerStatus
      };

      if (this.organization) {
        this.client.setGroup('companyName', this.organization.brandName);
        this.client.setGroup('orgId', this.organization._id);
        this.client.setUserProperties(userProperties);
      }
      const eventInfo = amplitudeEventKeys[eventKey];
      const eventProperties = { platform: 'Kiosk', category: 'Attendance', ...eventInfo };
      this.client.logEvent(eventInfo.eventName, eventProperties);
    } catch {
      // do nothing
    }
  }

  public async initialize() {
    try {
      if (this.API_KEY) {
        await this.createAmplitudeClient();
      }
    } catch {
      // do nothing
    }
  }

  private async createAmplitudeClient() {
    this.loggedUser = this.injector.get(AuthService).adminInfo;
    if (this.loggedUser?.userId) {
      this.organization = await this.injector.get(HttpClientService).get(
        `${environment.PEOPLE_CLOUD_APP_URL}/organization-db/organizations/my-organization`,
        this.injector.get(AuthService).getAuthHeader());

      const isFreeTrialAccount = this.organization?.isFreeTrialAccount === true;
      this.customerStatus = isFreeTrialAccount ? 'Free trial' : 'Customer';
    }

    this.ignoreAccount = this.shouldIgnoreAccount();
    if (this.ignoreAccount === true) {
      return;
    }
    this.client = amplitude.getInstance();
    this.client.init(this.API_KEY, this.loggedUser?.userId, {
      apiEndpoint: this.getAmplitudeProxyUrl(),
      forceHttps: this.getAmplitudeProxyUrlProtocol() === 'https:',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        Authorization: this.injector.get(AuthService).getAuthHeader()
      }
    } as any);
  }

  private shouldIgnoreAccount() {
    if (!environment.AMPLITUDE_IGNORE_DOMAINS?.length || !this.organization?.email) {
      return false;
    }

    const domains = environment.AMPLITUDE_IGNORE_DOMAINS.split(',');
    const accountFound = domains.some((iDomain) => this.organization.email.endsWith(iDomain));
    return accountFound;
  }

  private getAmplitudeProxyUrl() {
    const url = `${environment.PEOPLE_CLOUD_APP_URL}/amplitude/log-event`;
    return url.replace(/^(https?:|)\/\//, '');
  }

  private getAmplitudeProxyUrlProtocol() {
    const url = new URL(environment.PEOPLE_CLOUD_APP_URL);
    return url.protocol;
  }
}

export const amplitudeEventKeys = {
  VALID_2FA: { eventName: 'submit valid activation code', subcategory1: 'Activation' },
  REFRESH_LOCATIONS: { eventName: 'refresh kiosk location', subcategory1: 'select location step' },
  DEFINE_MESSAGE: { eventName: 'define welcome message', subcategory1: 'welcome message' },
  SKIP_MESSAGE: { eventName: 'skip welcome message', subcategory1: 'welcome message' },
  SWITCH_TO_QR: { eventName: 'switch to QR', subcategory1: 'PIN code intro screen' },
  SWITCH_TO_PIN: { eventName: 'switch to PIN code', subcategory1: 'QR reading screen' },
  INVALID_PIN: { eventName: 'invalid PIN code intro', subcategory1: 'PIN code intro screen' },
  CHECK_IN_PIN: { eventName: 'check in', subcategory1: 'PIN code', subcategory3: 'Clock' },
  KIOSK_BREAK: { eventName: 'kiosk break', subcategory1: '' },
  BREAK_REMINDER_TRIGGERED: { eventName: 'break reminder is triggered', subcategory1: 'Attendance Policies', subcategory2: 'Break Reminder Kiosk' },
  BREAK_REMINDER_SKIPPED: { eventName: 'break reminder is skipped', subcategory1: 'Attendance Policies', subcategory2: 'Break Reminder Kiosk' },
  BREAK_REMINDER_SAVED: { eventName: 'break reminder is saved with value', subcategory1: 'Attendance Policies', subcategory2: 'Break Reminder Kiosk' },
  MAX_DAILY_HOURS: { eventName: 'Max daily hours limit triggered', subcategory1: 'Max Daily hours', subcategory2: 'Max daily hours Kiosk' }
} as const;
