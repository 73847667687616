<div class="ci-container" [ngClass]="{'cc-shake-animation': incorrectCode}">
  <div *ngFor="let digit of digitsValues; index as index" class="ci-ellipse">
    <input
      #digit
      autocomplete="off"
      *ngIf="inputs"
      class="ci-digit-text-input kenjo-text-align-center"
      type="tel"
      (input)="onInput($event, index)"
      (keydown)="onKeydown($event, index)"
      [(value)]="digitsValues[index]"
      [ngClass]="{ 'ci-input-filled': digitsFilled[index] }"
      (paste)="onPaste($event, index)"
      (mousedown)="onMousedown($event, index)"
      [incorrectCode]="incorrectCode"
    />
    <div *ngIf="secureInput" [ngClass]="{ 'ci-hidden-input-visible': (digitsFilled[index + 1] && digitsFilled[index]) || (currentCode?.length && currentCode.length > index + 1 && digitsFilled[index])}" class="ci-hidden-input" (click)="focusFirstInput(index)">
      <div class="ci-hidden-input-element"></div>
    </div>
  </div>
</div>
