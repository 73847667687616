import { Injectable } from '@angular/core';
import { HttpClientService } from '@app/shared/services/http-client.service';
import { environment } from '@env';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from '@shared/services/auth.service';

type LocationsResponse = { locations: Array<ILocation>, error?: undefined } | { locations?: undefined, error: any };
@Injectable({ providedIn: 'root' })
export class LocationsService {
  public availableLocations$ = new BehaviorSubject<Array<ILocation> | 'loading' | 'error'>('loading');
  public locationsError$ = new BehaviorSubject<any>(undefined);

  constructor(private httpClient: HttpClientService, private authService: AuthService) { }

  async initAvailableLocations(): Promise<LocationsResponse> {
    const isAlreadyInit = Array.isArray(this.availableLocations$.value);
    if (isAlreadyInit) {
      return;
    }
    return this.getAvailableLocations();
  }

  async getAvailableLocations(): Promise<LocationsResponse> {
    try {
      this.availableLocations$.next('loading');
      const locations = await this.httpClient.get<Array<ILocation>>(`${environment.PEOPLE_CLOUD_APP_URL}/controller/kiosk/locations`, this.authService.getAuthHeader());
      this.availableLocations$.next(locations);
      return { locations };
    } catch(error) {
      this.availableLocations$.next('error');
      this.locationsError$.next(error);
      return { error };
    }
  }
}

export interface ILocation {
  _id: string,
  name: string,
  street: string,
  postalCode: string,
  city: string,
  country: string,
  calendarId: string,
  companyId: string,
  _createdById: string,
  _updatedById: string,
  ownerId: string,
  activeForShiftplan?: boolean
}
