import { Component, Input } from '@angular/core';

@Component({
  selector: 'kenjo-loading-spinner',
  template: `
  <div class="loading-spinner" [ngClass]="{'regular-loading-spinner': size === 'Regular', 'small-loading-spinner': size === 'Small', 'ls-green': color === 'green'}">
    <div class="bounce1"></div>
    <div class="bounce2"></div>
    <div class="bounce3"></div>
  </div>
  `,
  styleUrls: ['./loading-spinner.component.scss']
})
export class LoadingSpinnerComponent {
  @Input() size: string = 'Regular';
  @Input() color: 'green' | 'white' = 'white';
}
