import { Component, OnInit } from '@angular/core';
import { KioskSessionService } from '@app/check-in/services/kiosk-session.service';
import { BehaviorSubject } from 'rxjs';
import { ProgressService } from '../../services/progress.service';

@Component({
  selector: 'kenjo-header-bar',
  templateUrl: './header-bar.component.html',
  styleUrls: ['./header-bar.component.scss']
})
export class HeaderBarComponent implements OnInit {
  currentProgress$: BehaviorSubject<number>;
  constructor(private progressService: ProgressService, public kioskSessionService: KioskSessionService) { }

  ngOnInit(): void {
    this.currentProgress$ = this.progressService.currentProgress$;
  }
}
