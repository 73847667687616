import { isNgTemplate } from "@angular/compiler";
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren } from "@angular/core";
import { Subscription } from "rxjs";

@Component({
  selector: 'kenjo-code-input',
  templateUrl: 'code-input.component.html',
  styleUrls: ['code-input.component.scss']
})
export class CodeInputComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChildren('digit') inputsList!: QueryList<ElementRef>;
  @Output() codeChanged = new EventEmitter<string>();
  @Input() secureInput: boolean = false;
  @Input() codeLength: number = 6;
  @Input() incorrectCode: boolean;
  _currentCode: string = '';
  @Input() set currentCode(currentCode: string) {
    this._currentCode = currentCode;
    if (!currentCode?.length) {
      this.initControlVariables();
      this.initInputsList();
    }
  }
  get currentCode() {
    return this._currentCode;
  }

  inputDigit: number;
  digitsValues: string[] = [];
  digitsFilled: boolean[] = [];
  inputs: HTMLInputElement[] = [];
  private inputsListSubscription$: Subscription;

  constructor() {}

  ngOnInit(): void {
    this.initControlVariables();
  }

  ngAfterViewInit(): void {
    this.initInputsList();
  }

  ngOnDestroy(): void {
    if (this.inputsListSubscription$) {
      this.inputsListSubscription$.unsubscribe();
    }
  }

  initControlVariables() {
    const numbers = Array(this.codeLength - this.digitsValues.length).fill('');
    this.digitsFilled = Array(this.codeLength - this.digitsValues.length).fill(false);
    this.digitsValues.splice(this.digitsValues.length - 1, 0, ...numbers);
  }

  initInputsList() {
    if (!this.inputsList) {
      return;
    }
    if (!this.inputs.length) {
      const inputElements = this.inputsList.map(iElement => {
        const input = iElement.nativeElement;
        input.value = '';
        return input;
      });
      this.inputs.push(...inputElements);
    } else {
      this.inputs.forEach(input => input.value = "");
    }
    this.inputs[0]?.focus && this.inputs[0]?.focus();
  }

  onInput(e: any, i: number): void {
    const inputDigit = e.target;
    const value = e.data || inputDigit.value;

    if (!value?.length) {
      return;
    }

    if (!this.validInput(value)) {
      e.preventDefault();
      e.stopPropagation();
      inputDigit.value = '';
      return;
    }
    const values = value.toString().trim().split('');

    for (let j = 0; j < values.length; j++) {
      const index = j + i;
      if (index >= this.codeLength) {
        break;
      }
      this.setInputValue(this.inputs[index], values[j], index);
    }

    this.emitCode();

    const next = i + values.length;
    if (next > this.codeLength - 1) {
      this.inputs[this.codeLength - 1].blur();
      return;
    }

    this.inputs[next].focus();
  }

  isInputFilled(i: number): boolean {
    const inputValue = this.inputs[i]?.value;
    return inputValue === undefined || inputValue.length > 0;
  }

  private setInputValue(input: HTMLInputElement, value: any, index: number): void {
    if (!value?.length) {
      input.value = '';
      this.digitsFilled[index] = false;
    } else {
      input.value = value;
      this.digitsFilled[index] = true;
    }
  }

  private emitCode() {
    const currentCode = this.getCurrentFilledCode();
    this.codeChanged.emit(currentCode);
  }

  private validInput(value: any): boolean {
    return value?.length && /^[0-9]+$/.test(value.toString());
  }

  private getCurrentFilledCode(): string {
    return this.inputs.reduce((code, iInput) => {
      if (iInput.value?.length) {
        return code + iInput.value;
      }
      return code;
    }, '');
  }

  onKeydown(e: any, i: number): Promise<void> {
    const target = e.target;
    const isValueEmpty = !target.value?.length;
    const prev = i - 1;

    // processing only the backspace and delete key events
    const isBackspaceKey = this.isBackspaceKey(e);
    const isDeleteKey = this.isDeleteKey(e);
    if (!isBackspaceKey && !isDeleteKey) {
      return;
    }

    e.preventDefault();

    this.setInputValue(target, null, i);
    if (!isValueEmpty) {
      this.emitCode();
    }

    // preventing to focusing on the previous field if it does not exist or the delete key has been pressed
    if (prev < 0 || isDeleteKey) {
      return;
    }

    if (isValueEmpty) {
      this.inputs[prev].focus();
    }
  }

  private isDeleteKey(e: any): boolean {
    return (e.key && e.key.toLowerCase() === 'delete') || (e.keyCode && e.keyCode === 46);
  }

  private isBackspaceKey(e: any): boolean {
    return (e.key && e.key.toLowerCase() === 'backspace') || (e.keyCode && e.keyCode === 8);
  }

  onMousedown(e: any, i: number) {
    e.stopImmediatePropagation();
    const targetInput = e.target;
    const firstInput = this.inputs[0];
    if (firstInput === targetInput) {
      return;
    }
    const firstEmptyInputIndex: number = this.findFirstEmptyInputIndex();

    if (firstEmptyInputIndex === undefined) {
      return;
    }

    // Validate which input has been pressed
    if (i > firstEmptyInputIndex) {
      e.preventDefault();
      this.inputs[firstEmptyInputIndex].focus();
      return;
    }
  }

  findFirstEmptyInputIndex() {
    const firstEmptyInputIndex = this.inputs.findIndex((iInput) => !iInput?.value);
    return firstEmptyInputIndex !== -1 ? firstEmptyInputIndex : undefined;
  }

  onPaste(e: ClipboardEvent, i: number): void {
    e.preventDefault();
    e.stopPropagation();

    const data = e.clipboardData ? e.clipboardData.getData('text').trim() : undefined;

    if (!data?.length) {
      return;
    }

    // Convert paste text into iterable
    const values = data.split('');
    let valIndex = 0;

    for (let j = i; j < this.inputs.length && valIndex < values.length; j++) {
      const input = this.inputs[j];
      const val = values[valIndex];

      // Cancel the loop when a value cannot be used
      if (!this.validInput(val)) {
        this.setInputValue(input, null, valIndex);
        return;
      }

      this.setInputValue(input, val.toString(), valIndex);
      valIndex++;
    }
    const firstEmptyInputIndex = this.findFirstEmptyInputIndex();
    if (firstEmptyInputIndex === undefined) {
      this.inputs[this.inputs.length - 1].focus();
    } else {
      this.inputs[firstEmptyInputIndex].focus();
    }
    this.emitCode();
  }

  focusFirstInput(index: number) {
    const firstEmptyInputIndex: number = this.findFirstEmptyInputIndex();
    if (firstEmptyInputIndex === undefined) {
      this.inputs[index].focus();
      return;
    }
    this.inputs[Math.min(index, firstEmptyInputIndex)].focus();
  }
}
